module.exports = [{
      plugin: require('/fargate/b1aacdc/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lemans66","short_name":"lemans66","start_url":"/lemans66","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/website-icon.png"},
    },{
      plugin: require('/fargate/b1aacdc/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/fargate/b1aacdc/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
